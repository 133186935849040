import { Grid } from '@mui/material';
import useNonInitialEffect from '@versiondos/hooks';
import { clearAlerts, showErrorAlert } from 'actions/alerts/AlertsActions';
import { lookupCustomerThunk } from 'actions/customer/CustomerActions';
import { MarketplaceInfo } from 'components/Booking';
import { Heading } from 'components/Commons';
import EmergencyContactForm from 'components/Customer/EmergencyContactForm';
import { IntakeFormStep } from 'components/IntakeForm/IntakeForm';
import { IntakeFormLayout } from 'components/IntakeForm/Layout/Layout';
import { PrimaryButton } from 'components/UI/Buttons';
import { CustomField } from 'components/UI/FormsPart';
import { CustomMaskField } from 'components/UI/FormsPart/CustomMaskField/CustomMaskField';
import { CustomPhoneField } from 'components/UI/FormsPart/CustomPhoneField/CustomPhoneField';
import FormErrorList from 'components/UI/FormsPart/FormErrorList/FormErrorList';
import { EmergencyContact, MarketplaceType, ProgressBar } from 'model';
import { IntakeForm } from 'model/IntakeForm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertsState } from 'reducers/alerts/AlertsState';
import store, { RootState } from 'store';
import { validateEmail, validatePhone } from 'utils';

export interface IntakeFormCustomerProps {
    progress: ProgressBar;
    intakeForm: IntakeForm;
    onChange: (values: IntakeForm) => void;
    onContinue: () => void;
}

export const IntakeFormCustomer: React.FC<IntakeFormCustomerProps> = props => {
    const dispatch = useDispatch();
    const marketplace = store.getState().marketplace.marketplace;

    const [touched, setTouched] = React.useState({
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        address: false,
        city: false,
        state: false,
        zipCode: false
    });

    const [customer, setCustomer] = React.useState<IntakeForm['customer']>({
        firstName: props.intakeForm.customer.firstName ?? '',
        lastName: props.intakeForm.customer.lastName ?? '',
        email: props.intakeForm.customer.email ?? '',
        phone: props.intakeForm.customer.phone ?? '',

        address: {
            address: props.intakeForm.customer?.address?.address,
            city: props.intakeForm.customer?.address?.city,
            state: props.intakeForm.customer?.address?.state,
            zipCode: props.intakeForm.customer?.address?.zipCode
        },

        emergencyContact: props.intakeForm.customer.emergencyContact
    });

    const [isEmergencyContactRequired, setIsEmergencyContactRequired] = React.useState<boolean>(
        !!props.intakeForm.customer.emergencyContact?.first_name ?? false
    );

    const alerts = useSelector<RootState, AlertsState>(state => state.alert);

    const isAddressRequired = React.useMemo(() => {
        return [MarketplaceType.INHOME, MarketplaceType.MOBILE].includes(marketplace.businessType);
    }, [marketplace]);

    const errors = React.useMemo(() => {
        return Object.entries(customer).reduce(
            // eslint-disable-next-line
            (obj, item: [string, any], i) => {
                const name = item[0];
                const value = item[1];

                if (name === 'first_name') obj.first_name = value.length === 0;

                if (name === 'last_name') obj.last_name = value.length === 0;

                if (name === 'email') obj.email = value.length === 0 || validateEmail(value);

                if (name === 'phone') obj.phone = value.length === 0 || validatePhone(value);

                if (name === 'address' && isAddressRequired) {
                    obj.address = !value?.address || value?.address?.length === 0;
                    obj.city = !value?.city || value?.city?.length === 0;
                    obj.state = !value?.state || value?.state?.length === 0;
                    obj.zipCode = !value?.zipCode || value?.zipCode?.length === 0;
                }

                if (name === 'emergencyContact' && isEmergencyContactRequired) {
                    obj.emergencyContact =
                        value === undefined ||
                        (!value?.first_name &&
                            !value?.last_name &&
                            !value?.phone &&
                            !value?.relationship?.id);
                }

                return obj;
            },
            {
                first_name: false,
                last_name: false,
                email: false,
                phone: false,
                address: false,
                city: false,
                state: false,
                zipCode: false,
                emergencyContact: false
            }
        );
    }, [customer, isAddressRequired, isEmergencyContactRequired]);

    const invalid = React.useMemo(() => {
        return Object.values(errors).filter(v => v);
    }, [errors]);

    const hasError = React.useMemo(() => {
        return alerts.length > 0;
    }, [alerts]);

    const marketplaceIsCanadian = marketplace.address?.some(address => address.country === 'CA');

    const emergencyContactChangeHandler = (contact?: EmergencyContact) => {
        setCustomer(prev => ({ ...prev, emergencyContact: contact }));
    };

    const fieldChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        setTouched({ ...touched, [name]: true });

        if (['address', 'city', 'state', 'zipCode'].includes(name)) {
            setCustomer({
                ...customer,
                address: {
                    ...customer.address,
                    [name]: value
                }
            });
        } else {
            setCustomer({ ...customer, [name]: value });
        }
    };

    const backHandler = () => {
        dispatch(clearAlerts());
    };

    const continueHandler = async () => {
        const lookup = await dispatch(lookupCustomerThunk(props.intakeForm.customer.email));

        if (lookup === null) {
            return props.onContinue();
        }

        dispatch(
            showErrorAlert(
                'Oops! It looks like this email and/or phone number is already registered. Please reach out to us at your convenience so we can help.',
                IntakeFormStep.CustomerForm
            )
        );
    };

    useNonInitialEffect(() => {
        props.onChange({ ...props.intakeForm, customer });
    }, [customer]);

    return (
        <IntakeFormLayout
            showBack={hasError}
            onBack={backHandler}
            progress={props.progress}
            footer={
                hasError ? null : (
                    <PrimaryButton
                        label="Continue"
                        disabled={invalid.length > 0}
                        onClick={continueHandler}
                    />
                )
            }
        >
            <MarketplaceInfo hideLogo marketplace={marketplace} />

            <Heading title="Intake Form" />

            {hasError && (
                <>
                    <FormErrorList step="any" />
                </>
            )}

            {!hasError && (
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={6}>
                        <CustomField
                            id="personalInfo_input_firstname"
                            required
                            type="text"
                            name="firstName"
                            label="First Name"
                            value={customer.firstName}
                            onChange={fieldChangeHandler}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomField
                            id="personalInfo_input_lastname"
                            required
                            type="text"
                            name="lastName"
                            label="Last Name"
                            value={customer.lastName}
                            onChange={fieldChangeHandler}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomField
                            id="personalInfo_input_email"
                            required
                            type="email"
                            name="email"
                            label="Email"
                            value={customer.email}
                            onChange={fieldChangeHandler}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomPhoneField
                            id="personalInfo_input_phone"
                            required
                            type="text"
                            name="phone"
                            label="Phone Number"
                            value={customer.phone}
                            onChange={fieldChangeHandler}
                        />
                    </Grid>

                    {isAddressRequired && (
                        <>
                            <Grid item xs={12}>
                                <CustomField
                                    required
                                    type="text"
                                    name="address"
                                    label="Home Address"
                                    id="personalInfo_input_address"
                                    onChange={fieldChangeHandler}
                                    value={customer.address?.address}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomField
                                    required
                                    type="text"
                                    name="city"
                                    label="City"
                                    id="personalInfo_input_city"
                                    onChange={fieldChangeHandler}
                                    value={customer.address?.city}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomField
                                    required
                                    type="text"
                                    name="state"
                                    label="State"
                                    id="personalInfo_input_state"
                                    onChange={fieldChangeHandler}
                                    value={customer.address?.state}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {marketplaceIsCanadian ? (
                                    <CustomMaskField
                                        required
                                        type="text"
                                        mask="a9a9a9"
                                        name="zipCode"
                                        label="Zip Code"
                                        id="personalInfo_input_zipcode"
                                        onChange={fieldChangeHandler}
                                        value={customer.address?.zipCode ?? ''}
                                    />
                                ) : (
                                    <CustomMaskField
                                        required
                                        type="text"
                                        mask="99999"
                                        name="zipCode"
                                        label="Zip Code"
                                        id="personalInfo_input_zipcode"
                                        onChange={fieldChangeHandler}
                                        value={customer.address?.zipCode ?? ''}
                                    />
                                )}
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <EmergencyContactForm
                            values={customer.emergencyContact}
                            showForm={isEmergencyContactRequired}
                            onChange={emergencyContactChangeHandler}
                            onToggle={() => setIsEmergencyContactRequired(prev => !prev)}
                        />
                    </Grid>
                </Grid>
            )}
        </IntakeFormLayout>
    );
};
